<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script type="text/javascript">
	// 自适应
	function getRem() {
		var oWidth = document.body.clientWidth;
		document.documentElement.style.fontSize = oWidth / 7.5 + 'px';
	}
	getRem();
	window.addEventListener("resize", getRem, false); // 动态监听window的变化
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 0.32rem;
}
body {
	margin: 0;
}
img{
	vertical-align: bottom;
}

</style>
