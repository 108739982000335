<template>
    <div class="home">
        <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/z_mini/bj2.jpg">
        <div class="form">
            <form>
                <input type="text" placeholder="请输入企业全称" class="item" v-model="form.name" />
                <input type="text" placeholder="请输入社会统一信用代码" class="item" v-model="form.code" />
                <input type="text" placeholder="请输入企业法人姓名" class="item" v-model="form.legal_persona_name" />
                <input type="text" placeholder="请输入企业法人微信号" class="item" v-model="form.legal_persona_wechat" />
                <input type="text" placeholder="请输入企业法人联系电话" class="item" v-model="form.component_phone" />
                <div class="submit" @click="submitForm">提交认证</div>
            </form>
        </div>
    </div>
</template>

<script>
import {href,appid} from '../utils/config'
export default {
    name: "Home",
    data () {
        return {
            form: {
                name: '',
                code: '',
                code_type: '1',
                legal_persona_wechat: '',
                legal_persona_name: '',
                component_phone: '',
                utoken: ''
            },
            isWechat: false
        }
    },
    created () {
        //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
        var userAgent = window.navigator.userAgent.toLowerCase();
        //通过正则表达式匹配ua中是否含有MicroMessenger字符串
        if (userAgent.match(/MicroMessenger/i) == 'micromessenger') {
            this.isWechat = true
            let url = encodeURIComponent(href)
            let utoken = window.localStorage.getItem('utoken')
            // 第一次进来
            if (!window.location.search && !utoken) {
                // 授权拿到code
                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=wechat#wechat_redirect`
                window.close()
            }
            // 拿到code的页
            if (window.location.search) {
                // 获取utoken
                let params = window.location.search.split('?')[1]
                let code = params.split('&').find(v=>v.split('=')[0]=='code').split('=')[1]
                console.log(code, '------code')
                this.$request.post('/api/WechatUser/getUtoken', { code })
                    .then(res => {
                        if (res.code == 0) {
                            window.localStorage.setItem('utoken', res.result.utoken)
                            window.location.href = href
                            window.close()
                        }
                    })
            }
            // 拿到utoken的页
            if (utoken) {
                this.form.utoken = utoken
                console.log(this.form.utoken, '------utoken')
                window.localStorage.removeItem('utoken')
            }
        } else {
            this.$toast('请在微信内置浏览器中打开')
        }
    },
    methods: {
        submitForm () {
            this.$request.post('/api/MiniProgram/freeWechatPay', this.form)
                .then(res => {
                    if (res.code == 0) {
                        this.$toast('提交成功')
                        // let pay_params = res.result
                        // if (typeof WeixinJSBridge == "undefined") {
                        //     if (document.addEventListener) {
                        //         document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady(pay_params), false);
                        //     } else if (document.attachEvent) {
                        //         document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady(pay_params));
                        //         document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady(pay_params));
                        //     }
                        // } else {
                        //     this.onBridgeReady(pay_params);
                        // }
                    }
                })
        },
        // 微信支付
        onBridgeReady (pay_params) {
            WeixinJSBridge.invoke(
                'getBrandWCPayRequest', {
                "appId": pay_params.appId,     //公众号ID，由商户传入     
                "timeStamp": pay_params.timeStamp,//时间戳，自1970年以来的秒数     
                "nonceStr": pay_params.nonceStr, //随机串     
                "package": pay_params.package,
                "signType": pay_params.signType,  //微信签名方式：     
                "paySign": pay_params.paySign //微信签名 
            },
                function (res) {
                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                        // 使用以上方式判断前端返回,微信团队郑重提示：
                        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                    }
                }
            );
        }
    }
};
</script>

<style type="text/css" lang="scss">
.home {
    img {
        width: 100%;
        height: auto;
    }

    .form {
        position: absolute;
        top: 15rem;
        left: 0.7rem;
        right: 0.7rem;
    }

    .item {
        width: 100%;
        height: 0.9rem;
        background: #FFFFFF;
        border: 0.01rem solid #CCCCCC;
        border-radius: 0.04rem;
        padding: 0 0.34rem;
        margin-bottom: 0.2rem;
        font-size: 0.24rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        box-sizing: border-box;
    }

    .submit {
        width: 100%;
        height: 0.9rem;
        background: #4552FF;
        border-radius: 0.08rem;
        font-size: 0.28rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        line-height: 0.9rem;
    }
}</style>
