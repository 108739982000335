import axios from 'axios'
import { Toast } from 'vant';
import { baseUrl } from './config'
  
let qs = require('qs')

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
function post(url = '', data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(baseUrl + url, qs.stringify(data))
      .then((res) => {
        resolve(res.data)
		if(res.data.code!=0){
			Toast(res.data.msg)
		}
      })
      .catch((err) => {
        reject(err)
      })
  })
}

function get(url = '') {
  return new Promise((resolve, reject) => {
    axios.get(baseUrl + url)
      .then((res) => {
        resolve(res.data)
		if(res.data.code!=0){
			Toast(res.data.msg)
		}
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export default {
  post,
  get
}
